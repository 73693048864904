<template>
  <div>
    <b-table-filter
      :items="rows"
      :fields="fields"
      title="Báo Cáo Doanh Số"
      :perPage="perPage"
      :pageLength="pageLength"
      :isBusy="busy"
      :totalRows="totalRow"
    >
      <!-- <template #rightAction>
        <div>
          <b-button size="sm" variant="info"><feather-icon icon="CalendarIcon" /> Hôm Nay</b-button>
          <b-button size="sm"  variant="success"><feather-icon icon="CalendarIcon" /> Tuần Này</b-button>
          <b-button size="sm" variant="warning"><feather-icon icon="CalendarIcon" /> Tháng Này</b-button>
          <b-button size="sm"><feather-icon icon="CalendarIcon" /> Tháng Trước</b-button>
        </div>
      </template> -->
      <template #filterTable>
        <td style="width: 15%">
          <b-form-datepicker placeholder="Từ Ngày" size="sm" v-model="fromDate" />
        </td>
        <td style="width: 15%">
          <b-form-datepicker placeholder="Đến Ngày" size="sm" v-model="toDate" />
        </td>
        <td style="width: 15%">
          <vue-select placeholder="Tình Trạng Thanh Toán" class="select-size-sm" :options="[{value: 'tra_du', label: 'Trả Đủ'},{value: 'can_thanh_toan', label: 'Cần Thanh Toán'},]" />
        </td>
        <td style="width: 15%">
          <vue-select placeholder="Tình Trạng Đăng Ký" class="select-size-sm" :options="[{value: 'tra_du', label: 'Đã Xác Nhận'},{value: 'can_thanh_toan', label: 'Chưa Xác Nhận'},]" />
        </td>
        <td>
          Nếu không chọn Ngày Sẽ Lấy 5 ngày gần Nhất
        </td>
        <td>
          <div style="float:right">
            <b-button variant="warning" size="sm" @click="onSubmitFilter" style="margin-right: 5px"><feather-icon icon="SearchIcon" /> Xem Báo Cáo</b-button>
          </div>
        </td>
      </template>
    </b-table-filter>
  </div>
</template>

<script>
import {
  BFormDatepicker, BButton, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueSelect from 'vue-select'
import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    VueSelect,
    BFormDatepicker,
    BButton,
    BTableFilter,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      busy: false,
      totalRow: 1,
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'STT',
          key: 'id',
        },
        {
          label: 'Họ Tên Khách Hàng',
          key: 'fullName',
        },
        {
          label: 'Điện Thoại Khách Hàng',
          key: 'phone',
        },
        {
          label: 'Đã Trả',
          key: 'paid',
        },
        {
          label: 'Cần Thanh Toán',
          key: 'unPaid',
        },
        {
          label: 'Số Lượng Khóa Học',
          key: 'courseCount',
        },
        {
          label: 'Tình Trạng',
          key: 'status',
        },
        {
          label: 'Ghi Chú 1',
          key: 'note1',
        },
        {
          label: 'Ghi Chú 2',
          key: 'note2',
        },
      ],
      rows: [],
      fromDate: null,
      toDate: null,
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    onSubmitFilter() {
      this.busy = false
      console.log('onSubmitFilter', this.fromDate, this.toDate)
      this.rows = [
        {
          id: 1,
          fullName: 'Danh Sinh',
          phone: '096-998-5002',
          paid: 90000,
          courseCount: 10,
          status: 'Đã Xác Nhận',
          note1: 'AOIWDJAJWDJA ',
          note2: 'ĂDAWDAWDAWDA',
        },
      ]
    },
    onReset() {
      this.fromDate = null
      this.toDate = null
    },
  },
}
</script>
